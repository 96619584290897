import React, { useContext, useState, useEffect, useRef } from "react";
import { DeSoIdentityContext } from "react-deso-protocol";
import { useLocation } from 'react-router-dom';
import { getDisplayName } from "../helpers";
import {
  identity, transferDeSoToken,
  sendMessage, bs58PublicKeyToCompressedBytes,
  getHodlersForUser, getExchangeRates
} from "deso-protocol";
import logo from "../assets/desoguardlogo.svg";
import BigNumber from 'bignumber.js';
import userPrefsStore from 'context/userPrefsStore';
import { DollarSign, List, Shield, Loader2 } from 'lucide-react';
import { Card, CardContent, CardHeader, CardTitle } from "components/ui/card";
import { Button } from "components/ui/button"
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "components/ui/dialog"
import { RadioGroup, RadioGroupItem } from "components/ui/radio-group"
import { Label } from "components/ui/label"
import { Input } from "components/ui/input"
import { Textarea } from "components/ui/textarea"
import PercentagePieChart from "components/graph"
import CCTransactionLineChart from 'components/cclinegraph'
import DiamondsTransactionBarChart from 'components/diamonds'
import FollowUnfollowBarChart from 'components/followsunfollows'
import CreatorCoinsRatioBarChart from 'components/ccheldholders'
import SkeletonCard from 'components/skeletoncard'
import { Skeleton } from "components/ui/skeleton"
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandItem,
  CommandList,
  CustomCommandInput,
} from "components/ui/command"
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from "components/ui/avatar"



export const Home = () => {
  const ICON_SIZE = 48;
  const { currentUser, isLoading } = useContext(DeSoIdentityContext);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [resetTwitterAPI, setResetTwitterAPI] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [message, setMessage] = useState("");
  const { userPrefs, setUserPrefs } = useContext(userPrefsStore);
  const [canSubmit, setCanSubmit] = useState(false);
  const [availabilityMessage, setAvailabilityMessage] = useState('');
  const [username, setUsername] = useState('');
  const debounceRef = useRef(null);
  const [userProfiles, setUserProfiles] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const [hasStartedSearch, setHasStartedSearch] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const commandRef = useRef(null);
  const [isHuman, setIsHuman] = useState(false);
  const [isBot, setIsBot] = useState(false);
  const [isScam, setIsScam] = useState(false);
  const [isAI, setIsAI] = useState(false);
  const [notEnoughTransactions, setNotEnoughTransactions] = useState(false);
  const [apiResponse, setApiResponse] = useState('');
  const [apiError, setApiError] = useState('');
  const [creatorCoinTransactions, setCreatorCoinTransactions] = useState('');
  const [selectedUserProfile, setSelectedUserProfile] = useState(null);
  const [isProfileSelected, setIsProfileSelected] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [modalErrorReportMessage, setModalErrorReportMessage] = useState('');
  const [modalIsSendingErrorReport, setModalIsSendingErrorReport] = useState(false);
  const [modalErrorReportSent, setModalErrorReportSent] = useState(false);
  const [modalErrorReportSentComplete, setModalErrorReportSentComplete] = useState('');
  const [selectedUserBase64, setSelectedUserBase64] = useState('');
  const [selectedUserCCTransactions, setSelectedUserCCTransactions] = useState('');
  const [selectedUserNFTBuyNowTransactions, setSelectedUserNFTBuyNowTransactions] = useState('');
  const [selectedUserNFTBidTransactions, setSelectedUserNFTBidTransactions] = useState('');
  const [apiDebugMessage, setApiDebugMessage] = useState('');
  const [showBuyTokensButton, setShowBuyTokensButton] = useState(false);
  const [diamondsData, setDiamondsData] = useState({ sent: 0, received: 0 });
  const [followUnfollowData, setFollowUnfollowData] = useState({ follows: 0, unfollows: 0 });
  const [creatorCoinsData, setCreatorCoinsData] = useState({ held: 0, holders: 0 });
  const [searchUserInitiated, setSearchUserInitiated] = useState(false);
  const [tokensHeld, setTokensHeld] = useState(0);
  const [tokensHeldNumber, setTokensHeldNumber] = useState(0);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const publicKeyFromQuery = queryParams.get('publicKey');
  const userNameFromQuery = queryParams.get('userName');
  const supportDeSoGuard = queryParams.get('buyTokens');
  const [hasQueryStringEffectRun, setQueryStringHasEffectRun] = useState(false);
  const [isQueryString, setIsQueryString] = useState(false);
  const [showBuyModal, setShowBuyModal] = useState(false);
  const [showFinishedBuyModal, setFinishedShowBuyModal] = useState(false);
  const [selectedBuyValue, setSelectedBuyValue] = useState('1');
  const [otherBuyAmount, setOtherBuyAmount] = useState('');
  const [modalIsBuyingTokens, setModalIsBuyingTokens] = useState(false);
  const [userHasEnoughDeSo, setUserHasEnoughDeSo] = useState(true);
  



  const staticMessage = `Publickey searched: ${username}\nUsername searched: ${selectedUserProfile?.Username}\nDescribe Problem:\n`;
  const [userInput, setUserInput] = useState('');

  const handleInputChange = (e) => {
    setUserInput(e.target.value);
  };

  async function BuyTokens() {
    setApiError('')
    setModalIsBuyingTokens(true);
    const currentUserName = getDisplayName(currentUser);
    let amountDesoNanos = 0;
    let amountInDeso = 0;
    const amount = selectedBuyValue === 'other' ? otherBuyAmount : selectedBuyValue;

    if (amount === 0) {
      setApiError('You cannnot buy 0 tokens.');
      setFinishedShowBuyModal(true)
      setModalIsBuyingTokens(false);
      return;
    }
  
    try {
      const response = await getExchangeRates();
      const centsPerDeSoCoinbase = response.USDCentsPerDeSoCoinbase;
      amountInDeso = (amount * 100) / centsPerDeSoCoinbase;
      amountDesoNanos = Math.ceil((amountInDeso * 1e9) + 10000);
      if (amountDesoNanos > currentUser.BalanceNanos) {
        setUserHasEnoughDeSo(false)
        setFinishedShowBuyModal(true)
        setModalIsBuyingTokens(false);
        return;
      } else {
        setUserHasEnoughDeSo(true)
      }
    } catch (error) {
      console.error('Failed to get exchange rate:', error);
      setApiError('Failed to get exchange rate:', error || 'An error occurred');
      setFinishedShowBuyModal(true)
      setModalIsBuyingTokens(false);
      return;
    }
  
    try {
      if (
        !identity.hasPermissions({
          GlobalDESOLimit: amountDesoNanos,
          TransactionCountLimitMap: {
            NEW_MESSAGE: "UNLIMITED",
            BASIC_TRANSFER: 2
          },
          DAOCoinOperationLimitMap: {
            BC1YLhTs2vNbPa8FyrKucvLBs8hEyKyEUHAQgB7HUixzbfAJapmzQjx: {
              transfer: "UNLIMITED",
            }
          }
        })
      ) {
        await identity.requestPermissions({
          GlobalDESOLimit: amountDesoNanos,
          TransactionCountLimitMap: {
            NEW_MESSAGE: "UNLIMITED",
            BASIC_TRANSFER: 2
          },
          DAOCoinOperationLimitMap: {
            BC1YLhTs2vNbPa8FyrKucvLBs8hEyKyEUHAQgB7HUixzbfAJapmzQjx: {
              transfer: "UNLIMITED",
            }
          }
        });
      }
    } catch (error) {
      console.error('Error getting permission to buy tokens:', error);
      setApiError('Error getting permission to buy tokens:', error || 'Error getting permission to buy tokens');
      setFinishedShowBuyModal(true)
      setModalIsBuyingTokens(false);
      return;
    }
  
    const desoIdentityUsersStr = localStorage.getItem("desoIdentityUsers");
    const desoIdentityUsers = JSON.parse(desoIdentityUsersStr);
    const currentUserInfo = desoIdentityUsers[currentUser.PublicKeyBase58Check];
  
    const postData = {
      publickey: currentUser.PublicKeyBase58Check,
      username: currentUserName,
      derivedPublicKey: currentUserInfo.primaryDerivedKey.derivedPublicKeyBase58Check,
      derivedSeedHex: currentUserInfo.primaryDerivedKey.derivedSeedHex,
      desoNanoAmount: (amountDesoNanos - 10000)
    };
  
    try {
      const response = await fetch('https://2lcwfajr7c.execute-api.us-west-2.amazonaws.com/production/buytokens', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(postData)
      });

      const data = await response.json();
  
      if (data.statusCode === 200) {
        console.log('Successfully purchased tokens:', data);
        getTokenCount();
        setFinishedShowBuyModal(true);
        setModalIsBuyingTokens(false);
      } else {
        // Correctly handling non-200 response statuses
        const errorData = await response.text();
        try {
          const parsedError = JSON.parse(errorData);
          console.error('Error buying tokens:', parsedError);
          setApiError(`Error buying tokens: ${parsedError.error || 'Error buying tokens'}`);
        } catch (parseError) {
          // Handle case where the error response is not JSON
          console.error('Error buying tokens:', errorData);
          setApiError(`Error buying tokens: ${errorData}`);
        }
        setFinishedShowBuyModal(true);
        setModalIsBuyingTokens(false);
      }
    } catch (error) {
      console.error("Network error buying tokens:", error);
      setApiError(`Network error buying tokens: ${error.message || 'Error buying tokens'}`);
      setFinishedShowBuyModal(true);
      setModalIsBuyingTokens(false);
    }
  }


  function FeaturesCards() {
    const features = [
      {
        icon: <Shield size={ICON_SIZE} />,
        title: "Protect Yourself",
        description: "There are many bad actors on DeSo. Use our tool to help you evaluate your on-chain decisions and enjoy a better DeSo experience."
      },
      {
        icon: <DollarSign size={ICON_SIZE} />,
        title: "Public Beta",
        description: "Just 1 $desoscams token (~1 cent) per user check.",
        action: (
          <Button
            className="mt-2"
            onClick={() => setShowBuyModal(true)}
          >
            Buy Tokens
          </Button>
        )
      },
      {
        icon: <List size={ICON_SIZE} />,
        title: "AI Based Evaluation",
        description: "Our system uses AI to evaluate each account."
      }
    ];

    return (
      <div className="flex justify-center m-2 lg:m-10">
        <div className="grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 max-w-screen-xl mx-auto">
          {features.map((feature, idx) => (
            <Card className="w-[350px]" key={idx}>
              <CardHeader className="flex flex-col items-center">
                {feature.icon}
                <CardTitle style={{ marginTop: '2rem' }} className="mt-6">{feature.title}</CardTitle>
              </CardHeader>
              <CardContent>
                <p>{feature.description}</p>
                {feature.action}
                {/* Optional: Add any additional content per feature if needed */}
              </CardContent>
            </Card>
          ))}
        </div>
      </div>
    );
  }

  function convertBase58ToBase64(pubKeyBase58) {
    // Convert base58 public key to a byte array using the provided utility function
    const pubKeyBytes = bs58PublicKeyToCompressedBytes(pubKeyBase58);

    // Convert the byte array to a base64 string
    const base64PubKey = btoa(String.fromCharCode(...pubKeyBytes));

    return base64PubKey;
  }

  function formatNumberCompact(number) {
    if (number < 1000) {
      return number; // Return the number as-is if less than 1000
    } else if (number < 1000000) {
      return (number / 1000).toFixed(1) + 'k'; // Convert to thousands with one decimal place
    } else if (number < 1000000000) {
      return (number / 1000000).toFixed(1) + 'M'; // Convert to millions with one decimal place
    } else {
      return (number / 1000000000).toFixed(1) + 'B'; // Convert to billions with one decimal place
    }
  }
  async function getTokenCount() {
    if (!currentUser) {
      return; // Exit the function if currentUser is null
    }
    let tokenHolders = await getHodlersForUser({
      Username: "",
      FetchAll: true,
      FetchHodlings: true,
      PublicKeyBase58Check: currentUser.PublicKeyBase58Check,
      IsDAOCoin: true
    });
  
    // Ensure tokenHolders is not undefined or null
    if (tokenHolders && tokenHolders.Hodlers) {
      const { Hodlers } = tokenHolders;
      
      // Find the hodler by public key
      const hodler = Hodlers.find(h => h.CreatorPublicKeyBase58Check === "BC1YLhTs2vNbPa8FyrKucvLBs8hEyKyEUHAQgB7HUixzbfAJapmzQjx");
      
      // Update the state based on the found hodler
      if (hodler) {
        // Directly use the updated state value
        const balanceNanos = new BigNumber(hodler.BalanceNanosUint256); // BalanceNanos from your JSON
        const tokens = balanceNanos.dividedBy(new BigNumber("1e18"));
        setTokensHeldNumber(tokens)
        const formattedTokens = formatNumberCompact(Number(tokens.toFixed(0)));
        setTokensHeld(formattedTokens);
      } else {
        setTokensHeld(0);
        setTokensHeldNumber(0)
      }
    } else {
      console.log("No token holders data found");
      setTokensHeld(0);
      setTokensHeldNumber(0)
    }
  }

  const handleProfileSelect = (profile) => {
    setIsProfileSelected(true);
    setShowBuyTokensButton(false);
    setApiDebugMessage('');
    setApiResponse('');
    setApiError('');
    // Close the suggestions list
    setShowSuggestions(false);
    setHasStartedSearch(false);
    console.log("Selected User PublicKey:", profile);
    setSelectedUserProfile(profile);
    setUsername(profile.PublicKeyBase58Check);
    // Convert it to bytes using the utility function
    const publicKeyBase64 = convertBase58ToBase64(profile?.PublicKeyBase58Check);

    setSelectedUserBase64(publicKeyBase64);
    console.log(publicKeyBase64);

    // Set the input value to the selected username
    setInputValue(profile?.Username);
    setCanSubmit(true);



    // Reset or stop the search
    setSearchQuery(''); // Setting this to an empty string or to the selected user, depending on your implementation
    // Additional logic here...
    setShowSuggestions(false);
  };

  const executeGraphQLQuery = async (query, variables) => {
    const response = await fetch('https://graphql-prod.deso.com/graphql', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ query, variables }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json();
  };

  const fetchDiamondFollowerData = async (publicKey) => {
    const query = `
      query Query($publicKey: String!, $filter: TransactionFilter, $tokenBalancesAsCreatorFilter2: TokenBalanceFilter, $tokenBalancesFilter2: TokenBalanceFilter) {
        accountByPublicKey(publicKey: $publicKey) {
          diamondsSent {
            totalCount
          }
          diamondsReceived {
            totalCount
          }
          followers {
            totalCount
          }
          following {
            totalCount
          }
          tokenBalances(filter: $tokenBalancesFilter2) {
            totalCount
          }
          tokenBalancesAsCreator(filter: $tokenBalancesAsCreatorFilter2) {
            totalCount
          }
          transactions(filter: $filter) {
            nodes {
              txnType
              txIndexMetadata
            }
          }
        }
      }`;

    const variables = {
      publicKey: publicKey,
      filter: {
        txnType: {
          equalTo: 9
        }
      },
      tokenBalancesAsCreatorFilter2: {
        hasPurchased: {
          equalTo: true
        }
      },
      tokenBalancesFilter2: {
        hasPurchased: {
          equalTo: true
        }
      }
    };

    try {
      const response = await executeGraphQLQuery(query, variables);
      if (response.data && response.data.accountByPublicKey) {
        return response.data.accountByPublicKey; // Return the relevant part of the response
      } else {
        throw new Error('No data returned');
      }
    } catch (error) {
      console.error('Error fetching diamond and follower data:', error);
      throw error; // Re-throw to handle it in the calling context
    }
  };

  const nftBuyNowTransactions = async (publicKey) => {
    let allTransactions = [];
    let afterCursor = null;

    const query = `
      query AffectedPublicKeys($first: Int, $after: Cursor, $filter: AffectedPublicKeyFilter, $condition: AffectedPublicKeyCondition, $orderBy: [AffectedPublicKeysOrderBy!]) {
          affectedPublicKeys(filter: $filter, condition: $condition, orderBy: $orderBy, first: $first, after: $after) {
              nodes {
                  transaction {
                      txnType
                      txIndexMetadata
                      timestamp
                  }
              }
              pageInfo {
                  endCursor
                  hasNextPage
              }
          }
      }
  `;

    const fetchTransactions = async (after) => {
      const variables = {
        first: 5000,
        after: after,
        filter: {
          publicKey: {
            equalTo: publicKey
          },
          transaction: {
            txnType: {
              in: [18]
            },
            txIndexMetadata: {
              contains: {
                NFTRoyaltiesMetadata: {
                  CreatorPublicKeyBase58Check: publicKey
                },
                IsBuyNowBid: true
              }
            }
          }
        },
        condition: {
          isDuplicate: false
        },
        orderBy: "TIMESTAMP_DESC"
      };

      const response = await executeGraphQLQuery(query, variables);
      const transactions = response?.data?.affectedPublicKeys?.nodes?.map(node => node.transaction);
      const pageInfo = response?.data?.affectedPublicKeys?.pageInfo;

      return { transactions, pageInfo };
    };

    do {
      const { transactions, pageInfo } = await fetchTransactions(afterCursor);
      allTransactions = allTransactions.concat(transactions);
      afterCursor = pageInfo.hasNextPage ? pageInfo.endCursor : null;
    } while (afterCursor);

    return allTransactions;
  };

  const nftBidTransactions = async (publicKey) => {
    let allTransactions = [];
    let afterCursor = null;

    const query = `
      query AffectedPublicKeys($first: Int, $after: Cursor, $filter: AffectedPublicKeyFilter, $condition: AffectedPublicKeyCondition, $orderBy: [AffectedPublicKeysOrderBy!]) {
          affectedPublicKeys(filter: $filter, condition: $condition, orderBy: $orderBy, first: $first, after: $after) {
              nodes {
                  transaction {
                      txnType
                      txIndexMetadata
                      timestamp
                  }
              }
              pageInfo {
                  endCursor
                  hasNextPage
              }
          }
      }
  `;

    const fetchTransactions = async (after) => {
      const variables = {
        first: 5000,
        after: after,
        filter: {
          publicKey: {
            equalTo: publicKey
          },
          transaction: {
            txnType: {
              in: [17]
            },
            txIndexMetadata: {
              contains: {
                NFTRoyaltiesMetadata: {
                  CreatorPublicKeyBase58Check: publicKey
                }
              }
            }
          }
        },
        condition: {
          isDuplicate: false
        },
        orderBy: "TIMESTAMP_DESC"
      };

      const response = await executeGraphQLQuery(query, variables);
      const transactions = response?.data?.affectedPublicKeys?.nodes?.map(node => node.transaction);
      const pageInfo = response?.data?.affectedPublicKeys?.pageInfo;

      return { transactions, pageInfo };
    };

    do {
      const { transactions, pageInfo } = await fetchTransactions(afterCursor);
      allTransactions = allTransactions.concat(transactions);
      afterCursor = pageInfo.hasNextPage ? pageInfo.endCursor : null;
    } while (afterCursor);

    return allTransactions;
  };



  const checkUserCreatorCoinTransactions = async (publicKey, base64PublicKey) => {
    let allTransactions = [];
    let afterCursor = null;
    let totalSellDeSo = 0;
    let totalBuyDeSo = 0;
    let numberOfSelfBuys = 0;
    let numberOfSelfSells = 0;
    let mostRecentSaleDate = null; // Track the most recent sale date

    const query = `
    query Query($first: Int, $after: Cursor, $filter: AffectedPublicKeyFilter, $orderBy: [AffectedPublicKeysOrderBy!], $condition: AffectedPublicKeyCondition) {
      affectedPublicKeys(filter: $filter, orderBy: $orderBy, condition: $condition, first: $first, after: $after) {
        nodes {
          transaction {
            txIndexMetadata
            timestamp
            publicKey
          }
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
    `;

    const fetchTransactions = async (after) => {
      const variables = {
        "first": 5000,
        "after": after,
        "filter": {
          "publicKey": {
            "equalTo": publicKey
          },
          "txnType": {
            "equalTo": 11
          },
          "transaction": {
            "txnMeta": {
              "contains": {
                "ProfilePublicKey": base64PublicKey
              }
            }
          }
        },
        "orderBy": "TIMESTAMP_DESC",
        "condition": {
          "isDuplicate": false
        }
      };

      const response = await fetch('https://graphql-prod.deso.com/graphql', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ query, variables })
      });

      const data = await response.json();
      const transactions = data?.data?.affectedPublicKeys?.nodes?.map(node => node.transaction);
      const pageInfo = data?.data?.affectedPublicKeys?.pageInfo;

      return { transactions, pageInfo };
    };

    do {
      const { transactions, pageInfo } = await fetchTransactions(afterCursor);
      allTransactions = allTransactions.concat(transactions);
      // Check if pageInfo exists and only then attempt to read hasNextPage and endCursor
      if (pageInfo) {
        afterCursor = pageInfo.hasNextPage ? pageInfo.endCursor : null;
      } else {
        // If pageInfo is undefined, ensure the loop exits by setting afterCursor to null
        afterCursor = null;
      }
    } while (afterCursor);

    // Process transactions
    allTransactions.forEach(tx => {
      const desoLockedNanosDiff = parseInt(tx.txIndexMetadata?.DESOLockedNanosDiff || 0) / 1e9; // Convert from nanos
      if (tx.publicKey === publicKey) {
        if (tx.txIndexMetadata?.OperationType === 'buy') {
          numberOfSelfBuys++;
          totalBuyDeSo += desoLockedNanosDiff;
        } else if (tx.txIndexMetadata?.OperationType === 'sell') {
          if (numberOfSelfSells === 0) { // This condition ensures we capture the first (most recent) sell transaction
            mostRecentSaleDate = tx.timestamp;
          }
          numberOfSelfSells++;
          totalSellDeSo += desoLockedNanosDiff;
        }
      }
    });

    setSelectedUserCCTransactions(allTransactions)

    return {
      numberOfSelfBuys,
      numberOfSelfSells,
      totalBuyDeSo,
      totalSellDeSo,
      mostRecentSaleDate, // Include the most recent sale date in the return object
      transactions: allTransactions
    };
  };



  const handleUsernameSearch = (username) => {
    setSearchUserInitiated(false);
    setIsSubmitting(false);
    setHasStartedSearch(false);
    setCanSubmit(true);
    setShowBuyTokensButton(false);
    setIsHuman(false);
    setIsBot(false);
    setIsScam(false);
    setIsAI(false);
    setNotEnoughTransactions(false);
    setApiResponse('');
    setApiError('');
    setApiDebugMessage('');
    setIsSearching(true); // Start searching
    setCanSubmit(false);
    setHasStartedSearch(true);
    const enteredUsername = username;
    setUsername(enteredUsername);

    // Clear any existing debounce timeout
    if (debounceRef.current) {
      clearTimeout(debounceRef.current);
    }

    // Set a new debounce timeout
    debounceRef.current = setTimeout(() => {
      const currentInputValue = inputValue.trim();
      if (currentInputValue.trim() !== '') {
        searchUsername(currentInputValue); // Use the currentInputValue for the search
        setShowSuggestions(true);
      }
    }, 300);
  };

  const extractPercentage = (response) => {
    try {
      // Parse the JSON string back into an object
      const parsedResponse = JSON.parse(response);
      // Now, check if the parsedResponse has a 'type' object and a 'score' field
      if (parsedResponse && parsedResponse.type && parsedResponse.type.score !== undefined) {
        return parsedResponse.type.score; // Directly return the score
      }
    } catch (error) {
      // Handle parsing error or the case where response is not a valid JSON string
      console.error("Failed to parse apiResponse:", error);
    }
    return 0; // Returns 0 if parsing fails, or if the 'type' object or 'score' field is not found
  };

  const sendErrorReport = () => {
    // Determine the additional message based on conditions
    let typeOfReport = '';
    if (isHuman) {
      typeOfReport = "False Negative";
    } else if (isAI || isBot || isScam) {
      typeOfReport = "False Positive";
    }

    // Construct the full message to send
    const messageToSend = (typeOfReport ? `${typeOfReport}\n` : "") + staticMessage + userInput;

    setModalErrorReportSentComplete('');
    setModalIsSendingErrorReport(true);
    // Send message to DeSoScams
    const sendMessageParams = {
      SenderPublicKeyBase58Check: currentUser.PublicKeyBase58Check,
      RecipientPublicKeyBase58Check: "BC1YLhTs2vNbPa8FyrKucvLBs8hEyKyEUHAQgB7HUixzbfAJapmzQjx",
      Message: messageToSend, // Use the message from the modal
      AccessGroup: 'default-key',
    };

    sendMessage(sendMessageParams)
      .then(response => {
        console.log('Message sent successfully:', response);
        setModalIsSendingErrorReport(false);
        setModalErrorReportSent(true);
        setShowErrorModal(false);
        setModalErrorReportMessage('');
        setUserInput('')

        setModalErrorReportSentComplete('Message sent successfully! Thank you!');

        // Reset the success message state after a few seconds
        setTimeout(() => {
          setModalErrorReportSent(false);
          console.error('Timout sending message.');
          setModalIsSendingErrorReport(false);
          setModalErrorReportMessage('');
          setUserInput('')
          setModalErrorReportSentComplete('The message timed out. Please try again later.');
        }, 10000);
      })
      .catch(error => {
        console.error('Error sending message:', error);
        setModalIsSendingErrorReport(false);
        // Optionally handle error display in the UI
        setModalErrorReportMessage('');
        setUserInput('')
        setModalErrorReportSentComplete('Error sending message. Please try again later.');
      });
  };

  // Helper function to create a delay
  const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));

  async function fetchDesoGuardData(username, txnHashHex) {
    // Prepare the data for the POST request
    const postData = {
      publickey: username,
      username: selectedUserProfile?.Username,
      daotransaction: txnHashHex,
      userPublicKey: currentUser.PublicKeyBase58Check,
    };
  
    try {
      // Perform the POST request
      const response = await fetch(
        "https://zivlmbido3.execute-api.us-west-2.amazonaws.com/dev/invokedesoguard",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(postData),
        }
      );
  
      // Check if the response is okay
      if (!response.ok) {
        console.error(`Error: ${response.status}`);
        setApiError(`Error: ${response.status}`);
        setIsSubmitting(false);
        setSearchUserInitiated(false); // Stop submission process
        return null;  // Return null to indicate error
      }
  
      // Parse the response data
      const data = await response.json();
      console.log("API Response:", data);
  
      // Handle specific error status codes
      if (data.statusCode === 500) {
        let responseBody;
        try {
          responseBody = JSON.parse(data.body);
        } catch (error) {
          console.error("Error parsing response body:", error);
          setApiError("An unexpected error occurred.");
          setIsSubmitting(false);
          setSearchUserInitiated(false);
          return null;  // Return null to indicate error
        }
  
        if (
          responseBody &&
          typeof responseBody === "string" &&
          responseBody.includes("cannot unpack non-iterable NoneType object")
        ) {
          setApiError("Sorry, there was an error retrieving data for this profile.");
        } else {
          setApiError(responseBody || "An unexpected error occurred.");
        }
  
        setIsSubmitting(false);
        setSearchUserInitiated(false);
        return null;  // Return null to indicate error
      }
  
      // Get requestId from the response and start polling the /ping API
      const requestId = data.requestId;

      // Delay the start of the polling by 25 seconds
      //console.log("Waiting 25 seconds before starting to poll...");
      await sleep(1);  // Wait for 25 seconds
  
      // Start polling the /ping API every 5 seconds
      const desoGuardData = await pollForCompletion(requestId);
  
      // If the data is available, return it
      if (desoGuardData) {
        return desoGuardData;  // Return the data so it can be used by processDesoGuardResponse
      }
  
      return null;  // If polling didn't return valid data, return null
    } catch (error) {
      console.error("Error fetching data:", error);
      setApiError(`Error fetching data: ${error.message}`);
      setIsSubmitting(false);
      setSearchUserInitiated(false);
      return null;  // Return null in case of error
    }
  }
  
  // Poll the /ping endpoint for completion
async function pollForCompletion(requestId) {
  const pollInterval = 5000; // Poll every 5 seconds
  const maxTime = 150000; // Maximum time (150 seconds)
  let elapsedTime = 0; // Track elapsed time

  // Helper function to check the ping status
  async function checkPingStatus() {
    try {
      const pingResponse = await fetch(
        "https://zivlmbido3.execute-api.us-west-2.amazonaws.com/dev/ping",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ requestId }), // Pass the requestId in the body
        }
      );

      if (!pingResponse.ok) {
        console.error(`Ping error: ${pingResponse.status}`);
        return null;
      }

      const pingData = await pingResponse.json();
      console.log("Ping Response:", pingData);

      // Check if the response message indicates completion
      if (pingData.message === "Request completed successfully") {
        return pingData.resultData; // Return the resultData part of the response
      }

      return null;
    } catch (error) {
      console.error("Error checking ping status:", error);
      return null;
    }
  }

  return new Promise((resolve, reject) => {
    const intervalId = setInterval(async () => {
      elapsedTime += pollInterval;

      // Stop polling if the elapsed time exceeds maxTime (150 seconds)
      if (elapsedTime >= maxTime) {
        clearInterval(intervalId);
        reject(new Error("Timeout: Maximum polling time exceeded (150 seconds)."));
        return;
      }

      const pingResult = await checkPingStatus();

      if (pingResult) {
        clearInterval(intervalId); // Stop polling when the status is Complete
        resolve(pingResult); // Resolve with the ping result
      }
    }, pollInterval);
  });
}


  async function submitUsername() {
    setSearchUserInitiated(true)
    setShowBuyTokensButton(false);
    setIsSubmitting(true);
    setIsHuman(false);
    setIsBot(false);
    setIsScam(false);
    setIsAI(false); // Reset the AI state
    setNotEnoughTransactions(false);
    setApiResponse('');
    setApiError('');
    setCreatorCoinTransactions('');
    setSelectedUserNFTBuyNowTransactions('');
    setSelectedUserNFTBidTransactions('');

    try {
      // Assuming identity is available in this scope
      if (
        !identity.hasPermissions({
          TransactionCountLimitMap: {
            NEW_MESSAGE: "UNLIMITED"
          },
          DAOCoinOperationLimitMap: {
            BC1YLhTs2vNbPa8FyrKucvLBs8hEyKyEUHAQgB7HUixzbfAJapmzQjx: {
              transfer: "UNLIMITED",
            }
          }
        })
      ) {
        // if the user doesn't have permissions, request them
        // and abort the submit
        await identity.requestPermissions({
          GlobalDESOLimit: 10000000, // 0.01 DESO
          TransactionCountLimitMap: {
            NEW_MESSAGE: "UNLIMITED"
          },
          DAOCoinOperationLimitMap: {
            BC1YLhTs2vNbPa8FyrKucvLBs8hEyKyEUHAQgB7HUixzbfAJapmzQjx: {
              transfer: "UNLIMITED",
            }
          }
        });
      }

      const Enums = {
        values: {
          NANO_VALUE: 1e9, // Assuming this represents 1 billion
          HEX_PREFIX: '0x'
        }
      };
      const firstAmount = 1.0 * Enums.values.NANO_VALUE * Enums.values.NANO_VALUE; // Convert amount to "nano-nanos"
      const amountNanos = new BigNumber(firstAmount); // Convert amount to BigNumber
      const hexAmount = amountNanos.toString(16); // Convert BigNumber to hexadecimal string
      const finalAmount = Enums.values.HEX_PREFIX + hexAmount; // Add hex prefix

      // Ensure transferDeSoToken is defined and correctly handles promises
      await transferDeSoToken({
        ProfilePublicKeyBase58CheckOrUsername: 'BC1YLhTs2vNbPa8FyrKucvLBs8hEyKyEUHAQgB7HUixzbfAJapmzQjx',
        ReceiverPublicKeyBase58CheckOrUsername: 'BC1YLfx8tRyEJ1gDLbybbiZocwrUijyyA2ydjpyXe11RakuFDs2gKmx',
        DAOCoinToTransferNanos: finalAmount,
        SenderPublicKeyBase58Check: currentUser.PublicKeyBase58Check
      }).then(async (response) => {
        console.log('Transfer successful:', response);
        // Update Token count:
        getTokenCount();
        // Handle responses
        const txnHashHex = response.submittedTransactionResponse.TxnHashHex;
        const promises = [
          fetchDesoGuardData(selectedUserProfile?.PublicKeyBase58Check, txnHashHex).then(processDesoGuardResponse),
          checkUserCreatorCoinTransactions(selectedUserProfile?.PublicKeyBase58Check, selectedUserBase64).then(processCreatorCoinData),
          nftBuyNowTransactions(selectedUserProfile?.PublicKeyBase58Check).then(setSelectedUserNFTBuyNowTransactions),
          fetchDiamondFollowerData(selectedUserProfile?.PublicKeyBase58Check).then(processDiamondFollowerData),
          nftBidTransactions(selectedUserProfile?.PublicKeyBase58Check).then(setSelectedUserNFTBidTransactions)
        ];

        await Promise.allSettled(promises);
      }).catch(error => {
        console.error('Error transferring token:', error);
        // Set an appropriate error message
        setApiResponse("");
        setApiError("There was a problem transferring the token. Please check your balance and permissions:", error);
        setShowBuyTokensButton(true);
        setSearchUserInitiated(false);
        setIsSubmitting(false);
      });
    } catch (error) {
      console.error('Error during the operation:', error);
      setApiError("Error fetching data:", error);
    } finally {
      setIsSubmitting(false);
    }
  }

  const processDiamondFollowerData = (data) => {
    // Process diamonds data
    setDiamondsData({
      sent: data.diamondsSent.totalCount,
      received: data.diamondsReceived.totalCount,
    });

    // Initialize counters
    let follows = 0;
    let unfollows = 0;

    // Process follow/unfollow data
    data.transactions.nodes.forEach(node => {
      if (node.txnType === 9) { // Assuming 9 is the transaction type for follow/unfollow
        if (node.txIndexMetadata.IsUnfollow) {
          unfollows++;
        } else {
          follows++;
        }
      }
    });

    // Update state for follow/unfollow data
    setFollowUnfollowData({
      follows,
      unfollows,
    });
    // Process and set "Creator Coins Held/Holders" data
    setCreatorCoinsData({
      held: data.tokenBalances.totalCount,
      holders: data.tokenBalancesAsCreator.totalCount,
    });
  };

  function processDesoGuardResponse(desoGuardResponse) {
    // Assuming desoGuardResponse has a 'type' property that indicates the classification
    // Check if the parsed object has a debug field and it's not empty
    if (desoGuardResponse.debug && desoGuardResponse.debug !== "") {
      setApiDebugMessage(desoGuardResponse.debug);
    }
    if (desoGuardResponse.type.type) {
      console.log(desoGuardResponse);
      switch (desoGuardResponse.type.type) {
        case "AI":
          setIsAI(true);
          break;
        case "Scam":
          setIsScam(true);
          break;
        case "Bot":
          setIsBot(true);
          break;
        case "Not Enough Transactions":
          setNotEnoughTransactions(true);
          break;
        default:
          setIsHuman(true);
      }
      setApiResponse(JSON.stringify(desoGuardResponse));
    } else {
      // If the response does not contain a type, consider the user as human by default
      setIsHuman(true);
    }

  }

  function processCreatorCoinData(creatorCoinData) {
    let transactionMessage = '';
    const { numberOfSelfBuys, totalBuyDeSo, numberOfSelfSells, totalSellDeSo, mostRecentSaleDate } = creatorCoinData;

    if (numberOfSelfSells === 0) {
      transactionMessage = "They have never sold their creator coin.";
    } else {
      transactionMessage = `They have sold their creator coin ${numberOfSelfSells} times, totaling ${Math.abs(totalSellDeSo).toFixed(2)} DeSo`;
      if (mostRecentSaleDate) {
        const today = new Date();
        const lastSaleDate = new Date(mostRecentSaleDate);
        const differenceInDays = Math.floor((today - lastSaleDate) / (1000 * 3600 * 24));
        transactionMessage += `. It has been ${differenceInDays} days since the last sale.`;
      }
    }

    if (numberOfSelfBuys > 0) {
      transactionMessage += ` Additionally, they have bought their own creator coin ${numberOfSelfBuys} times, totaling ${totalBuyDeSo.toFixed(2)} DeSo.`;
    }

    // Update the state with the constructed message
    setCreatorCoinTransactions(transactionMessage);
  }


  const searchUsername = async (username) => {
    console.log("searching usernames");
    try {
      if (username.trim() === '') {
        setAvailabilityMessage('');
        setUserProfiles([]);
        setApiError('');
        return;
      }

      const payload = {
        PublicKeyBase58Check: "",
        Username: "",
        UsernamePrefix: username.trim(),
        Description: "",
        OrderBy: "",
        NumToFetch: 5,
        ReaderPublicKeyBase58Check: "BC1YLfx8tRyEJ1gDLbybbiZocwrUijyyA2ydjpyXe11RakuFDs2gKmx",
        ModerationType: "",
        FetchUsersThatHODL: false,
        AddGlobalFeedBool: false
      };

      const response = await fetch('https://node.deso.org/api/v0/get-profiles', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      const data = await response.json();

      if (data.ProfilesFound && data.ProfilesFound.length > 0) {
        setUserProfiles(data.ProfilesFound);
        setIsSearching(false);
      } else {
        setUserProfiles([]);
        setIsSearching(false);
        setAvailabilityMessage('No user profiles found.');
      }
    } catch (error) {
      setIsSearching(false);
      console.error('Error searching username:', error);
      setAvailabilityMessage('Error searching username.');
    }
  };


  const getProfilePictureUrl = (user) => {
    if (!user || !user.PublicKeyBase58Check) {
      // Return a default image URL or handle the lack of user data as needed
      return 'https://bitclout.com/assets/img/default_profile_pic.png';
    }
    return `https://diamondapp.com/api/v0/get-single-profile-picture/${user.PublicKeyBase58Check}?fallback=https://bitclout.com/assets/img/default_profile_pic.png`;
  };


  useEffect(() => {
    if (inputValue.trim() === '') {
      console.log("useEffect empty");
      setUserProfiles([]);
      setSelectedUserProfile(null);
      setApiError('');
    } else if (!isProfileSelected) {
      // Only call handleUsernameSearch if the change was not due to a profile selection
      setSelectedUserProfile(null);
      setShowSuggestions(true);
      handleUsernameSearch(inputValue);
    } else {
      // Reset the flag after handling the selection
      setIsProfileSelected(false);
    }
  }, [inputValue]);
  useEffect(() => {
    // Function to check if clicked outside of command
    function handleClickOutside(event) {
      if (commandRef.current && !commandRef.current.contains(event.target)) {
        setShowSuggestions(false);
        console.log("Click Outside")
      }
    }


    // Add event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Remove event listener on cleanup
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [commandRef]);

  useEffect(() => {
    getTokenCount();
   }, [currentUser]);
  // Get new token amount
  useEffect(() => {
    if (!isLoading && currentUser) {
      console.log(currentUser.PublicKeyBase58Check);
      setTokensHeld(0);
      setTokensHeldNumber(0)
      getTokenCount();
    }
    // removed , currentUser
  }, [isLoading]);

  useEffect(() => {
    // Check if all variables are available and the effect hasn't run yet
    if (!hasQueryStringEffectRun && publicKeyFromQuery && userNameFromQuery && currentUser?.PublicKeyBase58Check) {
      const profile = {
        PublicKeyBase58Check: publicKeyFromQuery,
        Username: userNameFromQuery,
      };
      setSelectedUserProfile({ PublicKeyBase58Check: publicKeyFromQuery, Username: userNameFromQuery });
      handleProfileSelect(profile);
      setUsername(publicKeyFromQuery);
      setInputValue(userNameFromQuery);
      setCanSubmit(true);
      setIsQueryString(true);
  
      // Set the flag to true so the effect doesn't run again
      setQueryStringHasEffectRun(true);
    }
    // Additional check for showing the buy modal
    if (!hasQueryStringEffectRun && supportDeSoGuard) {
      console.log("Buy Tokens")
      setShowBuyModal(true);
    }
    // This effect depends on these variables, but will only run the logic inside once
    // due to the internal condition and state flag.
    // removed currentUser,  to see if that fixed fu
  }, [publicKeyFromQuery, userNameFromQuery, currentUser, hasQueryStringEffectRun]);

  useEffect(() => {
    // Ensure there's a valid username before calling submitUsername
    console.log("Tokens")
    console.log(tokensHeld)
    if (username && isQueryString && tokensHeldNumber > 0) {
      submitUsername();
      setIsQueryString(false);
    }
  }, [username, isQueryString, tokensHeld]);

  return (

    <div>
      {showErrorModal &&
        <Dialog open={showErrorModal} onOpenChange={setShowErrorModal}>
          <DialogTrigger asChild>
            <button className="hidden">Open</button>
          </DialogTrigger>
          <DialogContent>
            <DialogHeader>
              <DialogTitle className="text-lg leading-6 font-medium text-white">Report an Error</DialogTitle>
              <DialogDescription className="mt-2 text-gray-300">
                Please describe the error you encountered.
              </DialogDescription>
            </DialogHeader>
            <Textarea
              onChange={handleInputChange}
            />
            <div className="px-1 py-3 sm:px-2 sm:flex sm:flex-row-reverse">
              <Button
                type="button"
                onClick={sendErrorReport}
                disabled={modalIsSendingErrorReport}
              >
                {modalIsSendingErrorReport ? <>
                  <Loader2 className="animate-spin" />
                  Sending...
                </> : 'Send'}
              </Button>
            </div>
          </DialogContent>
        </Dialog>
      }
      {showBuyModal && (
  <Dialog open={showBuyModal} onOpenChange={(open) => {
    setShowBuyModal(open);
    if (!open) { // If the dialog is being closed
      setSelectedBuyValue(1); // Reset selectedBuyValue to 1
      setModalIsBuyingTokens(false); 
    }
  }}>
    <DialogTrigger asChild>
      <button className="hidden">Open</button>
    </DialogTrigger>
    <DialogContent>
      <DialogHeader>
        <DialogTitle className="text-lg leading-6 font-bold text-white">
          Buy Tokens
        </DialogTitle>
        {!showFinishedBuyModal && (
          <DialogDescription className="mt-2 text-gray-300">
            Purchase $DESOSCAMS tokens at just one cent each, and use them to scan profiles for scams, bots, and rug-pulls. Each scan only costs 1 token!
          </DialogDescription>
        )}
      </DialogHeader>
            {!showFinishedBuyModal ? (
              <>
                <RadioGroup
                  defaultValue="5"
                  className="grid grid-cols-4 gap-4"
                  onValueChange={setSelectedBuyValue}
                >
                  <div>
                    <RadioGroupItem
                      value="5"
                      id="5"
                      className="peer sr-only"
                      aria-label="5"
                    />
                    <Label
                      htmlFor="5"
                      className="flex flex-col items-center justify-between rounded-md border-2 border-muted bg-transparent p-4 hover:bg-accent hover:text-accent-foreground peer-data-[state=checked]:border-primary [&:has([data-state=checked])]:border-primary"
                    >
                      <h1 className="text-lg text-1xl font-extrabold">$5</h1>
                    </Label>
                  </div>
                  <div>
                    <RadioGroupItem
                      value="25"
                      id="25"
                      className="peer sr-only"
                      aria-label="25"
                    />
                    <Label
                      htmlFor="25"
                      className="flex flex-col items-center justify-between rounded-md border-2 border-muted bg-transparent p-4 hover:bg-accent hover:text-accent-foreground peer-data-[state=checked]:border-primary [&:has([data-state=checked])]:border-primary"
                    >
                      <h1 className="text-lg text-1xl font-extrabold">$25</h1>
                    </Label>
                  </div>
                  <div>
                    <RadioGroupItem
                      value="50"
                      id="50"
                      className="peer sr-only"
                      aria-label="50"
                    />
                    <Label
                      htmlFor="50"
                      className="flex flex-col items-center justify-between rounded-md border-2 border-muted bg-transparent p-4 hover:bg-accent hover:text-accent-foreground peer-data-[state=checked]:border-primary [&:has([data-state=checked])]:border-primary"
                    >
                      <h1 className="text-lg text-1xl font-extrabold">$50</h1>
                    </Label>
                  </div>
                  <div>
                    <RadioGroupItem
                      value="other"
                      id="other"
                      className="peer sr-only"
                      aria-label="Other"
                    />
                    <Label
                      htmlFor="other"
                      className="flex flex-col items-center justify-between rounded-md border-2 border-muted bg-transparent p-4 hover:bg-accent hover:text-accent-foreground peer-data-[state=checked]:border-primary [&:has([data-state=checked])]:border-primary"
                    >
                      <h1 className="text-lg text-1xl font-extrabold">Other</h1>
                    </Label>
                  </div>
                </RadioGroup>
                {selectedBuyValue === 'other' && (
                  <div className="grid w-full max-w-sm items-center gap-1.5">
                    <Label htmlFor="other">Amount</Label>
                    <Input
                      type="number"
                      id="otheramount"
                      value={otherBuyAmount}
                      onKeyDown={(e) => {
                        // Allow backspace, enter, and numeric keys
                        if (
                          e.key === 'Backspace' ||
                          e.key === 'Enter' ||
                          e.key === 'Tab' || // Allowing tab for navigation
                          (e.keyCode >= 48 && e.keyCode <= 57 && !e.shiftKey) || // Numeric keys without Shift
                          (e.keyCode >= 96 && e.keyCode <= 105) // Numpad keys
                        ) {
                          // Allow these keys
                        } else {
                          // Prevent the default action of the key press
                          e.preventDefault();
                        }
                      }}
                      onChange={(e) => {
                        let value = e.target.value;
                        // Remove leading zeros (but allow the number "0")
                        if (value.startsWith('0') && value !== '0') {
                          value = value.replace(/^0+/, '');
                        }

                        if (value === '' || (!isNaN(value) && /^\d+$/.test(value))) {
                          setOtherBuyAmount(value);
                        }
                      }}
                      placeholder="$100"
                      step="1"
                    />
                  </div>
                )}
                <div>
                  <p className="text-xs">
                    *We will use DESO from your wallet equivalent to the amount specified using latest Coinbase Market Rates.
                  </p>
                </div>
                <div className="px-1 py-3 sm:px-2 sm:flex sm:flex-row-reverse">
                  <Button
                    type="button"
                    size="lg"
                    onClick={BuyTokens}
                    disabled={modalIsBuyingTokens}
                  >
                    {modalIsBuyingTokens ? (
                      <>
                        <Loader2 className="animate-spin" />
                        Buying...
                      </>
                    ) : (
                      'Buy'
                    )}
                  </Button>
                </div>
              </>
            ) : (
              <>
                {apiError ? (
                  <DialogDescription className="mt-2 text-red-500">
                    Error: {apiError}
                  </DialogDescription>
                ) : userHasEnoughDeSo ? (
                  <DialogDescription className="mt-2 text-green-500">
                    Purchase successful!
                  </DialogDescription>
                ) : (
                  <DialogDescription className="mt-2 text-red-500">
                    Sorry, you do not have enough DeSo to purchase the dollar amount you selected.
                  </DialogDescription>
                )}
                <div className="px-1 py-3 sm:px-2 sm:flex sm:flex-row-reverse">
                  <Button
                    type="button"
                    size="lg"
                    onClick={() => {
                      setShowBuyModal(false);
                      setApiError('');
                      setFinishedShowBuyModal(false);
                      setSelectedBuyValue(1)
                    }}
                  >
                    OK
                  </Button>
                </div>
              </>
            )}
          </DialogContent>
        </Dialog>
      )}
      {(!isLoading || userPrefs !== null) && (
        <>
          <div className="flex justify-center items-center m-4">
            <img src={logo} alt="Logo" className="w-full md:w-1/2 xl:w-1/3 h-auto" />
          </div>
          <h1 className="text-1xl font-extrabold leading-tight tracking-tighter lg:text-4xl mt-1 text-center">
             Protecting you from bad actors on DeSo
          </h1>
        </>
      )}

      {!isLoading && !currentUser && (
        <div className="mb-4 text-center">
          <p className="mt-5 mx-2 mb-3 text-gray-400">Login with your DeSo identity and approve the derived key to proceed.</p>
          <Button onClick={() => identity.login()}>
            Login
          </Button>
        </div>
      )}
      {!isLoading && currentUser && formSubmitted && (
        <div className="p-2 mt-2 text-center">
          <p className="mb-2 mx-2">Thank you, your sync preferences have been updated.</p>
          <Button onClick={() => {
            setFormSubmitted(false);
            setMessage("");
          }}>
            Change Preferences
          </Button>
        </div>
      )}

      {!isLoading && currentUser && !formSubmitted && (!userPrefs || resetTwitterAPI) && (
        <div className="p-4 text-center">
          <p>Token Balance: {tokensHeld}</p>
          {!tokensHeld && (
            <>
            <p className="mt-3">In order to scan a user you must first purchase tokens (cost per token is $0.01).</p>
            <Button
              className="mt-2" // Add any additional styling classes you need
              onClick={() => setShowBuyModal(true)} // Assumes setShowBuyModal is defined in your component
            >
              Buy Tokens
            </Button>
            </>
          )}
          {(!resetTwitterAPI && tokensHeldNumber > 0) && (
            <>
              <div className="flex flex-col items-start w-full lg:w-1/2 gap-1.5 mx-auto mt-3 relative">
                <Command className="rounded-lg border shadow-md" shouldFilter={false} ref={commandRef}>
                  <CustomCommandInput
                    placeholder="Search for a user..."
                    value={inputValue}
                    onValueChange={(newValue) => {
                      setInputValue(newValue);
                      setIsProfileSelected(false);
                    }}
                    selectedUserProfile={selectedUserProfile}
                  />

                  {showSuggestions && (
                    <CommandList>
                      {isSearching ? (
                        <CommandEmpty>Searching...</CommandEmpty>
                      ) : userProfiles.length > 0 ? (
                        <CommandGroup>
                          {userProfiles.map((profile) => (
                            <CommandItem
                              key={profile.PublicKeyBase58Check}
                              onSelect={() => handleProfileSelect(profile)}
                            >
                              <Avatar>
                                <AvatarImage src={getProfilePictureUrl(profile)} alt={profile?.Username} />
                                <AvatarFallback>{profile?.Username[0]}</AvatarFallback>
                              </Avatar>
                              <div className="ml-2">
                                <p className="text-sm font-medium leading-none">
                                  {profile?.Username}
                                </p>
                                {/* Additional details here if needed */}
                              </div>

                            </CommandItem>
                          ))}
                        </CommandGroup>
                      ) : (
                        <CommandEmpty>No user profiles found.</CommandEmpty>
                      )}
                    </CommandList>
                  )}
                </Command>



              </div>

            </>)}

          {(!resetTwitterAPI && tokensHeldNumber > 0) && (
            <div className="mt-3">
              <Button
                type="submit"
                disabled={isSubmitting || !canSubmit}
                onClick={submitUsername}
              >
                {canSubmit
                  ? (isSubmitting
                    ? (
                      <>
                        <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                        Please Wait
                      </>
                    )
                    : <>Submit</>
                  )
                  : <>Select a User</>}
              </Button>
            </div>
          )}
          {notEnoughTransactions && !isAI && selectedUserProfile && (
            <>
              <div className="flex flex-row items-center justify-center w-full lg:w-1/3 mx-auto mt-6 relative gap-1.5">
                <div>
                  <Avatar>
                    <AvatarImage src={getProfilePictureUrl(selectedUserProfile)} alt={selectedUserProfile?.Username} />
                    <AvatarFallback>{selectedUserProfile?.Username[0]}</AvatarFallback>
                  </Avatar>
                </div>
                <div className="ml-2">
                  <p className="text-2xl font-medium leading-9">
                    {selectedUserProfile?.Username} does not have enough transactions to be analyzed.
                  </p>

                </div>
                <Button
                  type="button"
                  variant="outline"
                  onClick={() => { setShowErrorModal(true) }}>Report Error
                </Button>
                {modalErrorReportSent && modalErrorReportSentComplete && (
                  <div className="text-green-500 text-sm mt-3">
                    {modalErrorReportSentComplete}
                  </div>
                )}
              </div>
            </>
          )}
          {isHuman && !isAI && selectedUserProfile && (
            <>
              <div className="flex flex-row items-center justify-center w-full lg:w-1/3 mx-auto mt-6 relative gap-1.5">
                <div>
                  <Avatar>
                    <AvatarImage src={getProfilePictureUrl(selectedUserProfile)} alt={selectedUserProfile?.Username} />
                    <AvatarFallback>{selectedUserProfile?.Username[0]}</AvatarFallback>
                  </Avatar>
                </div>
                <div className="ml-2">
                  <p className="text-2xl font-medium leading-9">
                    {selectedUserProfile?.Username} seems to be a reliable account that does not seem to show any suspicious behavior.
                  </p>

                </div>
              </div>
              <div className="flex flex-row items-center justify-center w-full lg:w-1/3 mx-auto mt-6 relative gap-1.5">
                <p className="leading-6">But remember to always do your own research to verify any details or claims independently.</p>
              </div>
              <div className="flex flex-row items-center justify-center w-full lg:w-1/2 mx-auto mt-6 relative gap-1.5">
              </div>
              <div className="mb-3">
                <Button
                  className="mt-3"
                  type="button"
                  variant="outline"
                  onClick={() => { setShowErrorModal(true) }}>Report False Negative
                </Button>
                {modalErrorReportSent && modalErrorReportSentComplete && (
                  <div className="text-green-500 text-sm mt-3">
                    {modalErrorReportSentComplete}
                  </div>
                )}
              </div>
            </>
          )}


          {isBot && !isScam && selectedUserProfile && (
            <div className="mt-4">
              <div className="flex flex-row items-center justify-center w-full lg:w-1/3 mx-auto mt-6 relative gap-1.5">
                <div>
                  <Avatar>
                    <AvatarImage src={getProfilePictureUrl(selectedUserProfile)} alt={selectedUserProfile?.Username} />
                    <AvatarFallback>{selectedUserProfile?.Username[0]}</AvatarFallback>
                  </Avatar>
                </div>
                <div className="ml-2">
                  <p className="text-2xl font-medium leading-6">
                    {selectedUserProfile?.Username} has some bot-like activity.
                  </p>
                </div>
              </div>
              <div className="items-center justify-center w-full lg:w-1/3 mx-auto mt-6 relative gap-1.5">
                <p className="leading-6">Our analysis suggests that there might be some automated patterns in {selectedUserProfile?.Username}'s account activities. This doesn't necessarily mean they're not human – sometimes, genuine accounts use automation for certain tasks. We encourage further review to understand the context better.</p>
              </div>
              <div className="items-center justify-center w-full lg:w-1/3 mx-auto mt-6 relative gap-1.5">
                <div style={{ width: '100%', height: '200px', minHeight: '100px' }}>
                  <PercentagePieChart
                    percentage={extractPercentage(apiResponse)}
                  />
                </div>
              </div>
              <div className="flex flex-row items-center justify-center w-full lg:w-1/2 mx-auto mt-6 relative gap-1.5">
                
              </div>
              <div className="mb-3">
                <Button
                  className="mt-3 mb-3"
                  type="button"
                  variant="outline"
                  onClick={() => { setShowErrorModal(true) }}>Report False Positive
                </Button>
                {modalErrorReportSent && modalErrorReportSentComplete && (
                  <div className="text-green-500 text-sm mt-3">
                    {modalErrorReportSentComplete}
                  </div>
                )}
              </div>
              
            </div>
          )}
          {isScam && !isBot && selectedUserProfile && (
            <div className="mt-4">
              <div className="flex flex-row items-center justify-center w-full lg:w-1/3 mx-auto mt-6 relative gap-1.5">
                <div>
                  <Avatar>
                    <AvatarImage src={getProfilePictureUrl(selectedUserProfile)} alt={selectedUserProfile?.Username} />
                    <AvatarFallback>{selectedUserProfile?.Username[0]}</AvatarFallback>
                  </Avatar>
                </div>
                <div className="ml-2">
                  <p className="text-2xl font-medium leading-6">
                    {selectedUserProfile?.Username} could be a scammer account.
                  </p>
                </div>
              </div>
              <div className="items-center justify-center w-full lg:w-1/3 mx-auto leading-6 mt-6 relative gap-1.5">
                <p className="text-2xl mt-3 font-medium leading-6">
                  Use extra caution.
                </p>
                <p className="mt-2">We recommend approaching interactions with caution and suggest verifying any details or claims independently.</p>
              </div>
              <div className="items-center justify-center w-full lg:w-1/3 mx-auto mt-6 relative gap-1.5">
                <div style={{ width: '100%', height: '200px', minHeight: '100px' }}>
                  <PercentagePieChart
                    percentage={extractPercentage(apiResponse)}
                  />
                </div>
              </div>
              <div className="flex flex-row items-center justify-center w-full lg:w-1/2 mx-auto mt-6 relative gap-1.5">
                {creatorCoinTransactions ? (
                  <p>{creatorCoinTransactions}</p>
                ) : (
                  <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                )}
              </div>
              <Button
                className="mt-3"
                type="button"
                variant="outline"
                onClick={() => { setShowErrorModal(true) }}>Report False Positive
              </Button>
              {modalErrorReportSent && modalErrorReportSentComplete && (
                <div className="text-green-500 text-sm mt-3">
                  {modalErrorReportSentComplete}
                </div>
              )}
            </div>
          )}
          {isAI && selectedUserProfile && (
            <div className="mt-4">
              <div className="flex flex-row items-center justify-center w-full lg:w-1/3 mx-auto mt-6 relative gap-1.5">
                <div>
                  <Avatar>
                    <AvatarImage src={getProfilePictureUrl(selectedUserProfile)} alt={selectedUserProfile?.Username} />
                    <AvatarFallback>{selectedUserProfile?.Username[0]}</AvatarFallback>
                  </Avatar>
                </div>
                <div className="ml-2">
                  <p className="text-2xl font-medium leading-6">
                    {selectedUserProfile?.Username} seems like they are using AI to write their content.
                  </p>
                </div>
              </div>
              <div className="items-center justify-center w-full lg:w-1/2 mx-auto mt-6 relative gap-1.5">
                <p className="leading-6">We recommend approaching interactions with caution and suggest verifying any details or claims independently before rewarding their activity on-chain.</p>
              </div>
              <div className="items-center justify-center w-full lg:w-1/3 mx-auto mt-6 relative gap-1.5">
                <div style={{ width: '100%', height: '200px', minHeight: '100px' }}>
                  <PercentagePieChart
                    percentage={extractPercentage(apiResponse)}
                  />
                </div>
              </div>
              <div className="flex flex-row items-center justify-center w-full lg:w-1/2 mx-auto mt-6 relative gap-1.5">
                {creatorCoinTransactions ? (
                  <p>{creatorCoinTransactions}</p>
                ) : (
                  <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                )}
              </div>
              <Button
                className="mt-3"
                type="button"
                variant="outline"
                onClick={() => { setShowErrorModal(true) }}>Report False Positive
              </Button>
              {modalErrorReportSent && modalErrorReportSentComplete && (
                <div className="text-green-500 text-sm mt-3">
                  {modalErrorReportSentComplete}
                </div>
              )}
            </div>
          )}

{searchUserInitiated && (!isAI && !isHuman && !isBot && !isScam && !notEnoughTransactions) && ( 
            <div className="mt-4">
            <div className="flex flex-row items-center justify-center w-full lg:w-1/2 mx-auto mt-6 relative gap-1.5">
      <Skeleton className="h-12 w-12 rounded-full" />
      <div className="space-y-2">
        <Skeleton className="h-4 w-[350px]" />
        <Skeleton className="h-4 w-[300px]" />
      </div>
              </div>
              <div className="flex flex-row items-center justify-center w-full lg:w-1/2 mx-auto mt-6 relative gap-1.5">
              <Skeleton className="h-4 w-1/2" />
              </div>
              <div className="flex flex-row items-center justify-center w-full lg:w-1/2 mx-auto mt-2 relative gap-1.5">
              <Skeleton className="h-4 w-1/2" />
              </div>
              <div className="flex flex-row items-center justify-center w-full lg:w-1/2 mx-auto mt-2 relative gap-1.5">
              <Skeleton className="h-4 w-1/2" />
              </div>
              <div className="flex flex-row items-center justify-center w-full lg:w-1/2 mx-auto mt-2 relative gap-1.5">
                
                <Skeleton className="h-[200px] mt-4 w-1/2" />
          
              </div>
              <div className="flex flex-row items-center justify-center w-full lg:w-1/2 mx-auto mt-6 relative gap-1.5">
              </div>
            </div>
            )}
          


          {message && <p className="mt-2 text-red-600">{message}</p>}

          {apiError && (
            <div className="error-message mt-2 text-red-600">{apiError}</div>
          )}



          {showBuyTokensButton && (
            <Button
              className="mt-2"
              onClick={setShowBuyModal(true)}
            >
              Buy Tokens
            </Button>
          )}

          {apiDebugMessage && (
            <div className="error-message mt-2 text-red-600">{apiDebugMessage}</div>
          )}
          {searchUserInitiated && (
            <>
            <div className="flex flex-row items-center justify-center w-full lg:w-1/2 mx-auto mt-6 relative gap-1.5">
            {creatorCoinTransactions ? (
                  <p>{creatorCoinTransactions}</p>
                ) : (
                  <Skeleton className="h-4 w-1/2" />
                )}
                </div>
                
              <div className="flex flex-col mt-4">
                {/* CCTransactionLineChart Full-Width */}
                <div className="w-full">
                {selectedUserCCTransactions && creatorCoinTransactions ? (
                <CCTransactionLineChart
                  CCTransactions={selectedUserCCTransactions}
                  NFTBuyNowTransactions={selectedUserNFTBuyNowTransactions}
                  NFTBidTransactions={selectedUserNFTBidTransactions}
                  publicKey={selectedUserProfile?.PublicKeyBase58Check}
                />
              ) : ( <SkeletonCard /> )}
                  
                </div>
              </div>
              {/* Three Divs Layout Below CCTransactionLineChart */}
              <div className="flex flex-wrap mt-8 -mx-4">
                {/* First Div - Placeholder or another component */}
                <div className="w-full md:w-1/3 px-4 mb-8 md:mb-0">

                {followUnfollowData ? (
                      <FollowUnfollowBarChart followUnfollowData={followUnfollowData} />
                    ) : (
                      <SkeletonCard />
                    )}

                </div>

                {/* Second Div - DiamondsTransactionBarChart */}
                <div className="w-full md:w-1/3 px-4 mb-8 md:mb-0">

                {diamondsData ? (
                      <DiamondsTransactionBarChart diamondsData={diamondsData} />
                    ) : (
                      <SkeletonCard />
                    )}

                </div>

                {/* Third Div - Placeholder or another component */}
                <div className="w-full md:w-1/3 px-4">
                {creatorCoinsData ? (
                      <CreatorCoinsRatioBarChart creatorCoinsData={creatorCoinsData} />
                    ) : (
                      <SkeletonCard />
                    )}

                </div>
              </div>
            </>
          )}

        </div>
      )}
      {(!isLoading) ? <FeaturesCards /> : ""}



    </div>

  );
}